<template>

    <div class="d-lg-none">

        <div v-if="loading">
            <div class="card bg-info mt-4">
                <loading-animation/>
                Please wait while loading data from the server.
            </div>
        </div>

        <div v-else-if="error">
            <div class="card bg-danger mt-4">
                <error-icon/>
                Failed to load data from the server.
            </div>
        </div>
        <div v-else>
            <div v-for="(i, k) in details" v-bind:key="k" class="mt-4">

                <div class="b-1 bs-3">

                    <div class="row  fs--1 m-0">
                    <span class="w-100 col-12 b-1">
                        <div class="p-2">
                           <span class="font-inter-medium">Auction Id</span>: &nbsp; <span class="ml-2">
                            {{ i.auction_id }}</span>
                        </div>
                    </span>
                        <span class="w-100 col-12 b-1">
                        <div class="p-2">
                           <span class="font-inter-medium">Cancelled On</span>: &nbsp; <span class="ml-2">
                            {{ i.cancelled_on }}</span>
                        </div>
                    </span>
                        <span class="w-100 col-12 b-1">
                        <div class="p-2">
                           <span class="font-inter-medium">Cancelled By</span>: &nbsp; <span class="ml-2">
                            {{ i.cancelled_by }}</span>
                        </div>
                    </span>
                        <span class="w-100 col-12 b-1">
                        <div class="p-2">
                           <span class="font-inter-medium">Cancelled Reason</span>:<br> <span class="ml-2">
                            {{ i.cancelled_reason }}</span>
                        </div>
                    </span>
                    </div>

                </div>
            </div>

            <div v-if="details.length === 0" class="card">
                <div class="mb-2">
                    <div>
                        <div class="fl-x fl-j-c fl-a-c">
                            No Data available.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name : 'CancelHistoryMobileView',

    data () {
        return {
            id      : this.$route.params.id,
            loading : true,
            error   : false,
            details : null
        };
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    mounted () {
        this.loadData();
    },

    methods : {
        loadData () {
            const that = this;

            that.loading = true;
            that.error = false;
            axios.get(urls.auction.auction.cancelHistory + '?auction_id=' + this.id, {}).then(function (response) {
                const json = response.data;
                that.details = [...json.data];
                that.loading = false;
                that.error = false;
            }).catch(function (exception) {
                console.log('exception : ', exception);
                that.loading = false;
                that.error = true;
            });
        }
    }
};
</script>
