<template>
    <div>
        <inner-page-card :scrollbar="true" :link-back="true" :horz-padding="true">

            <template #header-area>
                <div class="fl-x">
                    <span class="mr-2">
                        <img src="../../../assets/web/icons/header-icons/auction-list-icon.png" class="icon-sm" alt="">
                    </span>
                    <p class="font-inter-medium text-center d-inline fs-lg-2 m-0">Cancel History</p>
                </div>
            </template>

            <template #default>
                <custom-vue-table class="d-sm-none" title="" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                           :show-search-box="false" :extra-params="{auction_id : id}"/>
                <cancel-history-mobile-view class="d-lg-none"></cancel-history-mobile-view>

            </template>

        </inner-page-card>
    </div>

</template>

<script>
import urls from '@/data/urls';
import CancelHistoryMobileView from './CancelHistoryMobileView';

export default {
    name       : 'CancelHistory',
    components : { CancelHistoryMobileView },
    data () {
        return {
            id      : this.$route.params.id,
            listUrl : urls.auction.auction.cancelHistory,
            fields  : [
                {
                    name  : 'auction_id',
                    title : 'Auction ID'
                },
                {
                    name  : 'cancelled_on',
                    title : 'Cancelled On'
                },
                {
                    name  : 'cancelled_by',
                    title : 'Cancelled By'
                },
                {
                    name  : 'cancelled_reason',
                    title : 'Cancelled Reason'
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
